$panel-border-bottom-width: 0 !default;
$panel-border-left-width: 0 !default;
$panel-border-right-width: 0 !default;
$panel-border-top-width: 0 !default;

$panel-footer-border-top-width: 0 !default;

$panel-heading-border-bottom-width: 2px !default;

$panel-group-panel-spacer: 0 !default;

$panel-border-radius: 0 !default;
$panel-footer-bg: #F5F8FA !default;

$panel-box-shadow: $list-group-box-shadow !default;

$panel-blank-heading-link-color: $text-color !default;
$panel-blank-heading-link-open-color: $brand-primary !default;

$panel-default-border: $brand-primary !default;
$panel-default-heading-bg: #F5F8FA !default;
$panel-default-text: $brand-default !default;

$panel-primary-border: darken($brand-primary, 25%) !default;