$user-icon-font-weight: 500 !default;

$user-icon-font-size: 10px !default;
$user-icon-size: 24px !default;

$user-icon-desktop-font-size: $font-size-base !default; // 14px
$user-icon-desktop-size: 32px !default;

// Skin

$user-icon-default-bg: $brand-default !default;