$badge-desktop-font-size: $label-desktop-font-size !default;
$badge-font-size: $label-font-size !default;
$badge-font-weight: 500 !default;
$badge-padding-bottom: $label-padding-bottom !default;
$badge-padding-left: $label-padding-left !default;
$badge-padding-right: $label-padding-right !default;
$badge-padding-top: $label-padding-top !default;

$badge-lg-desktop-font-size: $label-lg-desktop-font-size !default;
$badge-lg-font-size: $label-lg-font-size !default;
$badge-lg-padding-bottom: $label-lg-padding-bottom !default;
$badge-lg-padding-left: $label-lg-padding-left !default;
$badge-lg-padding-right: $label-lg-padding-right !default;
$badge-lg-padding-top: $label-lg-padding-top !default;

$badge-sm-desktop-font-size: $label-sm-desktop-font-size !default;
$badge-sm-font-size: $label-sm-font-size !default;
$badge-sm-padding-bottom: $label-sm-padding-bottom !default;
$badge-sm-padding-left: $label-sm-padding-left !default;
$badge-sm-padding-right: $label-sm-padding-right !default;
$badge-sm-padding-top: $label-sm-padding-top !default;

// Skin

$badge-default-bg: $label-default-bg !default;
$badge-default-color: $label-default-color !default;

// Deprecated `$badge-default-link-color` as of v1.0.16 and will be removed in v2.0.0
$badge-default-hover-color: $badge-default-color !default;
$badge-default-link-color: $badge-default-color !default;