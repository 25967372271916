// Structure

$breadcrumb-font-size: 12px !default;
$breadcrumb-font-weight: 500 !default;
$breadcrumb-text-transform: uppercase !default;

// Skin

$breadcrumb-bg: transparent !default;

$breadcrumb-active-color: #C1CBD1 !default;
$breadcrumb-color: #D4DADF !default;
$breadcrumb-link-color: #4A5E6A !default;