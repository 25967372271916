$label-desktop-font-size: $font-size-base !default; // Atlas
$label-font-size: ceil($font-size-base / 1.166667) !default; // Atlas
$label-font-weight: 500 !default;
$label-lexicon-icon-height: 10px !default;
$label-lexicon-icon-width: $label-lexicon-icon-height !default;
$label-padding-bottom: 9px !default;
$label-padding-left: 12px !default;
$label-padding-right: 12px !default;
$label-padding-top: 9px !default;

$label-lg-desktop-font-size: $font-size-large !default;
$label-lg-font-size: ceil($font-size-large / 1.166667) !default;
$label-lg-lexicon-icon-height: 14px !default;
$label-lg-lexicon-icon-width: $label-lg-lexicon-icon-height !default;
$label-lg-padding-bottom: 14px !default;
$label-lg-padding-left: 20px !default;
$label-lg-padding-right: 20px !default;
$label-lg-padding-top: 14px !default;

$label-sm-desktop-font-size: $font-size-small !default;
$label-sm-font-size: ceil($font-size-small / 1.166667) !default;
$label-sm-lexicon-icon-height: 7px !default;
$label-sm-lexicon-icon-width: $label-sm-lexicon-icon-height !default;
$label-sm-padding-bottom: 4px !default;
$label-sm-padding-left: 10px !default;
$label-sm-padding-right: 10px !default;
$label-sm-padding-top: 4px !default;

// Skin

$label-default-bg: #E7F1F9 !default;
$label-default-color: $brand-primary !default;
$label-default-hover-color: $label-default-color !default;