.sticker {
	border-radius: $sticker-border-radius;
	color: $sticker-color;
	display: inline-block;
	font-size: $sticker-font-size;
	left: $sticker-gutter-width;

	@include monospace($sticker-monospaced-size);

	padding: 0;
	position: absolute;
	text-align: center;
	top: $sticker-gutter-width;

	.lexicon-icon {
		margin-top: -1px;
	}
}

.sticker-static {
	position: static;
}

// Sticker Positions

.sticker-bottom-left {
	bottom: $sticker-gutter-width;
	right: auto;
	top: auto;
}

.sticker-bottom-right {
	bottom: $sticker-gutter-width;
	left: auto;
	right: $sticker-gutter-width;
	top: auto;
}

.sticker-top-right {
	left: auto;
	right: $sticker-gutter-width;
}

.sticker-bottom {
	bottom: $sticker-gutter-width;
	top: auto;
}

.sticker-right {
	left: auto;
	right: $sticker-gutter-width;
}

.sticker-outside {
	left: -$sticker-gutter-width;
	top: -$sticker-gutter-width;

	&.sticker-bottom-left {
		bottom: -$sticker-gutter-width;
		top: auto;
	}

	&.sticker-bottom-right {
		bottom: -$sticker-gutter-width;
		left: auto;
		right: -$sticker-gutter-width;
		top: auto;
	}

	&.sticker-top-right {
		left: auto;
		right: -$sticker-gutter-width;
	}

	&.sticker-bottom {
		bottom: -$sticker-gutter-width;
		top: auto;
	}

	&.sticker-right {
		left: auto;
		right: -$sticker-gutter-width;
	}
}

// Sticker Sizes

.sticker-sm {
	font-size: $sticker-sm-font-size;

	@include monospace($sticker-sm-monospaced-size);

	.lexicon-icon {
		height: $sticker-sm-font-size;
		margin-top: -2px;
		width: $sticker-sm-font-size;
	}
}

.sticker-lg {
	font-size: $sticker-lg-font-size;

	@include monospace($sticker-lg-monospaced-size);

	.lexicon-icon {
		height: $sticker-lg-font-size;
		margin-top: -3px;
		width: $sticker-lg-font-size;
	}
}

// Sticker Skins

.sticker-circle {
	@if ($sticker-border-radius != $sticker-circle-border-radius) {
		border-radius: $sticker-circle-border-radius;
	}
}

.sticker-rounded {
	@if ($sticker-border-radius != $sticker-rounded-border-radius) {
		border-radius: $sticker-rounded-border-radius;
	}
}

.sticker-square {
	@if ($sticker-border-radius != null or $sticker-border-radius != $sticker-square-border-radius) {
		border-radius: $sticker-square-border-radius;
	}
}

.sticker-danger {
	background-color: $sticker-danger-bg;
	color: $sticker-danger-color;
}

.sticker-default {
	background-color: $sticker-default-bg;
	color: $sticker-default-color;
}

.sticker-info {
	background-color: $sticker-info-bg;
	color: $sticker-info-color;
}

.sticker-primary {
	background-color: $sticker-primary-bg;
	color: $sticker-primary-color;
}

.sticker-success {
	background-color: $sticker-success-bg;
	color: $sticker-success-color;
}

.sticker-warning {
	background-color: $sticker-warning-bg;
	color: $sticker-warning-color;
}