.btn {
	border-width: $btn-border-width;

	@if (variable-exists(atlas-theme)) {
		@include button-size($btn-padding-vertical, $btn-padding-horizontal, $btn-font-size, $line-height-base, $btn-border-radius);

		@media (min-width: $grid-float-breakpoint) {
			@include button-size($btn-desktop-padding-vertical, $btn-desktop-padding-horizontal, $btn-desktop-font-size, $btn-line-height, $btn-border-radius);
		}

		&:active,
		&.active {
			box-shadow: $btn-active-box-shadow;
		}

		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			opacity: $btn-disabled-opacity;
		}
	}
}

@if (variable-exists(atlas-theme)) {
	.btn-default {
		@include color-btn($btn-default-border);
		@include color-btn-hover($btn-default-hover-bg, $btn-default-hover-border);
		@include color-btn-active($btn-default-active-bg, $btn-default-active-border);
	}

	.btn-primary {
		@include color-btn($btn-primary-border);
		@include color-btn-hover($btn-primary-hover-bg, $btn-primary-hover-border);
		@include color-btn-active($btn-primary-active-bg, $btn-primary-active-border);
	}

	.btn-success {
		@include color-btn($btn-success-border);
		@include color-btn-hover($btn-success-hover-bg, $btn-success-hover-border);
		@include color-btn-active($btn-success-active-bg, $btn-success-active-border);
	}

	.btn-info {
		@include color-btn($btn-info-border);
		@include color-btn-hover($btn-info-hover-bg, $btn-info-hover-border);
		@include color-btn-active($btn-info-active-bg, $btn-info-active-border);
	}

	.btn-warning {
		@include color-btn($btn-warning-border);
		@include color-btn-hover($btn-warning-hover-bg, $btn-warning-hover-border);
		@include color-btn-active($btn-warning-active-bg, $btn-warning-active-border);
	}

	.btn-danger {
		@include color-btn($btn-danger-border);
		@include color-btn-hover($btn-danger-hover-bg, $btn-danger-hover-border);
		@include color-btn-active($btn-danger-active-bg, $btn-danger-active-border);
	}

	.btn-link {
		@include color-btn(transparent);
		@include color-btn-hover(transparent, transparent);

		&:active,
		&.active,
		&:active:focus,
		&:active:hover,
		&.active:focus,
		&.active:hover,
		.open > &.dropdown-toggle,
		.open > &.dropdown-toggle:focus,
		.open > &.dropdown-toggle:hover {
			background-color: transparent;
			border-color: transparent;
			color: $link-color;
		}
	}

	.btn-lg {
		@include button-size($btn-lg-padding-vertical, $btn-lg-padding-horizontal, $btn-lg-font-size, $btn-line-height, $btn-border-radius-large);

		@media (min-width: $grid-float-breakpoint) {
			@include button-size($btn-desktop-lg-padding-vertical, $btn-desktop-lg-padding-horizontal, $btn-desktop-lg-font-size, $btn-line-height, $btn-border-radius-large);
		}
	}

	.btn-sm {
		@include button-size($btn-sm-padding-vertical, $btn-sm-padding-horizontal, $btn-sm-font-size, $btn-line-height, $btn-border-radius-small);

		@media (min-width: $grid-float-breakpoint) {
			@include button-size($btn-desktop-sm-padding-vertical, $btn-desktop-sm-padding-horizontal, $btn-desktop-sm-font-size, $btn-line-height, $btn-border-radius-small);
		}
	}

	.btn-xs {
		@include button-size($btn-xs-padding-vertical, $btn-xs-padding-horizontal, $btn-xs-font-size, $btn-line-height, $btn-border-radius-extra-small);

		@media (min-width: $grid-float-breakpoint) {
			@include button-size($btn-desktop-xs-padding-vertical, $btn-desktop-xs-padding-horizontal, $btn-desktop-xs-font-size, $btn-line-height, $btn-border-radius-extra-small);
		}
	}
}

// Action Buttons

.btn-action,
.btn-action-secondary {
	z-index: $zindex-btn-action;
}

.btn-action,
.btn-action-secondary .btn {
	border-radius: $btn-action-border-radius;
	height: $btn-action-size;
	padding: 0;
	position: relative;
	width: $btn-action-size;

	@if (variable-exists(btn-desktop-action-size)) {
		@media (min-width: $grid-float-breakpoint) {
			height: $btn-desktop-action-size;
			width: $btn-desktop-action-size;
		}
	}

	// Font Awesome & Glyphicon

	[class*="icon-"] {
		line-height: $btn-action-size - ($btn-border-width * 2);

		@if (variable-exists(btn-desktop-action-size)) {
			@media (min-width: $grid-float-breakpoint) {
				line-height: $btn-desktop-action-size - ($btn-border-width * 2);
			}
		}
	}

	.glyphicon {
		margin-left: 2px;
		margin-top: -2px;
	}
}

.btn-action {
	.lexicon-icon-container,
	.lexicon-icon {
		display: block;
		height: 100%;
		margin: auto;
	}
}

.btn-action-secondary {
	display: inline-block;

	.dropdown-menu-left-side,
	.dropdown-menu-left-side-bottom {
		margin-right: $btn-action-dropdown-spacer;
	}

	.dropdown-menu-right-side,
	.dropdown-menu-right-side-bottom {
		margin-left: $btn-action-dropdown-spacer;
	}
}

.btn-bottom-left {
	bottom: $btn-fixed-spacer-y;
	left: $btn-fixed-spacer-x;
	position: fixed;
}

.btn-bottom-right {
	bottom: $btn-fixed-spacer-y;
	position: fixed;
	right: $btn-fixed-spacer-x;
}

.btn-top-left {
	left: $btn-fixed-spacer-x;
	position: fixed;
	top: $btn-fixed-spacer-y;
}

.btn-top-right {
	position: fixed;
	right: $btn-fixed-spacer-x;
	top: $btn-fixed-spacer-y;
}