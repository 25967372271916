// Structure

$card-border-radius: 0 !default;
$card-border-width: 0 !default;

$checkbox-left-card-padding: 50px !default;

// Skin

$card-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.9) !default;
$card-link-color: $brand-default !default;