$table-bg-accent: darken(#FCFEFF, 2%) !default;
$table-bg-hover: #FCFEFF !default;

// Deprecated `$table-header-color` as of v1.0.17 and will be removed in v2.0.0
$table-header-color: $brand-default !default;
$table-header-font-size: 14px !default;
$table-header-font-weight: 500 !default;

$table-list-body-row-bg: #FFF !default;

$table-list-row-active-border-bottom-width: 2px !default;
$table-list-row-active-border-color: $brand-primary !default;
$table-list-row-height: 72px !default;

$table-list-color: $table-header-color !default;
$table-list-link-color: $brand-default !default;