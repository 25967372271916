// Structure

$popover-arrow-width: 8px !default;
$popover-max-width: 276px !default;

$popover-padding: 0 !default;

$popover-title-font-size: 16px !default;
$popover-title-margin: 0 16px !default;
$popover-title-padding: 16px 0 !default;

$popover-content-padding: 16px !default;

// Skin

$popover-border-color: #AEB7BD !default;
$popover-fallback-border-color: #AEB7BD !default;
$popover-border-radius: $border-radius-base !default; // 4px

$popover-box-shadow-x: $box-shadow-default-x !default; // 0
$popover-box-shadow-y: $box-shadow-default-y !default; // 0

$popover-box-shadow-blur: $box-shadow-default-blur !default; // 3px
$popover-box-shadow-spread: $box-shadow-default-spread !default; // 1px

$popover-box-shadow-bg: $box-shadow-default-bg !default; // rgba(0, 0, 0, 0.2)

$popover-box-shadow: $popover-box-shadow-x $popover-box-shadow-y $popover-box-shadow-blur $popover-box-shadow-spread $popover-box-shadow-bg !default;

$popover-title-bg: #FFF !default;
$popover-title-color: $text-color !default;