.has-success {
	label,
	.control-label {
		color: $input-success-label-color;
	}

	.checkbox,
	.radio {
		label {
			color: $input-success-checkbox-label-color;
		}
	}

	.form-control {
		background-color: $input-success-bg;
		border-color: $input-success-border;
		box-shadow: $input-success-box-shadow;
		color: $input-success-color;

		&:focus {
			background-color: $input-success-bg-focus;
			border-color: $input-success-border-focus;
			box-shadow: $input-success-box-shadow-focus;
			color: $input-success-color-focus;
		}
	}
}

.has-warning {
	label,
	.control-label {
		color: $input-warning-label-color;
	}

	.checkbox,
	.radio {
		label {
			color: $input-warning-checkbox-label-color;
		}
	}

	.form-control {
		background-color: $input-warning-bg;
		border-color: $input-warning-border;
		box-shadow: $input-warning-box-shadow;
		color: $input-warning-color;

		&:focus {
			background-color: $input-warning-bg-focus;
			border-color: $input-warning-border-focus;
			box-shadow: $input-warning-box-shadow-focus;
			color: $input-warning-color-focus;
		}
	}
}

.has-error {
	label,
	.control-label {
		color: $input-danger-label-color;
	}

	.checkbox,
	.radio {
		label {
			color: $input-danger-checkbox-label-color;
		}
	}

	.form-control {
		background-color: $input-danger-bg;
		border-color: $input-danger-border;
		box-shadow: $input-danger-box-shadow;
		color: $input-danger-color;

		&:focus {
			background-color: $input-danger-bg-focus;
			border-color: $input-danger-border-focus;
			box-shadow: $input-danger-box-shadow-focus;
			color: $input-danger-color-focus;
		}
	}
}

@if (variable-exists(atlas-theme)) {
	.has-success {
		select.form-control {
			background-image: lx-icon(caret-double-l, $state-success-border);
			background-image: none \9;
		}
	}

	.has-warning {
		select.form-control {
			background-image: lx-icon(caret-double-l, $state-warning-border);
			background-image: none \9;
		}
	}

	.has-error {
		select.form-control {
			background-image: lx-icon(caret-double-l, $state-danger-border);
			background-image: none \9;
		}
	}

	.form-group select.form-control[multiple] {
		background-image: none;
		background-position: 0% 0%;
		background-size: auto;
		padding: $input-padding-vertical $input-padding-horizontal;
	}
}