.sidebar {
	height: 100%;
	overflow: auto;

	-webkit-overflow-scrolling: touch;

	h1,
	.h1 {
		margin-bottom: 0;
		margin-top: 0;
	}

	h2,
	.h2 {
		margin-bottom: 0;
		margin-top: 3px;
	}

	h3,
	.h3 {
		margin-bottom: 0;
		margin-top: 5px;
	}

	h4,
	.h4 {
		margin-bottom: 0;
		margin-top: 6px;
	}

	h5,
	.h5 {
		margin-bottom: 0;
		margin-top: 8px;
	}

	h6,
	.h6 {
		margin-bottom: 0;
		margin-top: 9px;
	}

	.sidebar-title {
		display: block;
		font-weight: $headings-font-weight;
		margin-bottom: 8px;
	}
}

.sidebar-header {
	@include clearfix;

	margin: 0;
	padding: $sidebar-padding;
	word-wrap: break-word;
}

// Deprecated `.sidebar-header-actions` in favor of `.sidebar-actions` as of
// v1.0.12 and will be removed in v2.0.0.

.sidebar-actions,
.sidebar-header-actions {
	float: right;

	@include list-unstyled;

	margin-bottom: 0;
	margin-right: -9px;

	> li {
		display: inline-block;
	}
}

.sidebar-caption {
	color: $text-muted;
}

.sidebar-body {
	font-size: 14px;
	overflow: auto;

	-webkit-overflow-scrolling: touch;

	padding: $sidebar-padding;

	dt {
		padding-top: 0.1px;
	}

	dd.sidebar-caption {
		margin-bottom: 20px;
		margin-top: 3px;
	}
}

.sidebar-footer {
	padding: $sidebar-padding;

	a {
		text-decoration: none;
	}
}

.sidebar-block {
	margin-bottom: 32px;
	position: relative;
	word-wrap: break-word;
}

.sidebar-panel {
	height: 155px;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
}

// Sidebar Navbars

.sidebar {
	.management-bar-default,
	.navbar-default,
	.navbar-inverse {
		border-left-width: 0;
		border-radius: 0;
		border-right-width: 0;
		margin-bottom: 0;
	}

	@if (variable-exists(atlas-theme)) {
		.navbar > .navbar-collapse {
			@media (min-width: $grid-float-breakpoint) {
				padding-left: $sidebar-navbar-gutter-left;
				padding-right: $sidebar-navbar-gutter-right;

				> .navbar-nav {
					margin-right: 0;
				}
			}
		}

		.navbar-no-collapse > .navbar-nav,
		.navbar > .navbar-header {
			padding-left: $sidebar-navbar-gutter-left;
			padding-right: $sidebar-navbar-gutter-right;
		}

		.navbar-collapse .open .dropdown-menu > .dropdown-header,
		.navbar-collapse .dropdown-menu > li > a,
		.navbar-collapse > .navbar-nav > li > a {
			@media (max-width: $grid-float-breakpoint-max) {
				@if ($sidebar-padding-left > $navbar-padding-horizontal) {
					padding-left: $sidebar-padding-left;
				}

				@if ($sidebar-padding-right > $navbar-padding-horizontal) {
					padding-right: $sidebar-padding-right;
				}
			}
		}

		.navbar > [class*='container-fluid'] {
			padding-left: $sidebar-navbar-gutter-left;
			padding-right: $sidebar-navbar-gutter-right;

			> .navbar-collapse > .navbar-nav,
			> .navbar-header,
			> .navbar-nav {
				margin-left: 0;
				margin-right: 0;
			}

			> .navbar-collapse {
				@media (max-width: $grid-float-breakpoint-max) {
					margin-left: -$sidebar-navbar-gutter-left;
					margin-right: -$sidebar-navbar-gutter-right;
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
	@else {
		.navbar > [class*='container-fluid'] > .navbar-nav {
			margin-left: 0;
			margin-right: 0;
		}

		.navbar > [class*='container-fluid'] > .navbar-collapse {
			@media (min-width: $grid-float-breakpoint) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}

// Skins

.sidebar-default {
	background-color: $sidebar-default-bg;
	border-left: 1px solid $sidebar-default-border;
	color: $sidebar-default-color;

	.sidebar-header {
		.sidebar-title {
			color: $sidebar-default-header-title-color;
		}

		a.sidebar-title,
		.sidebar-title a {
			color: $sidebar-default-header-title-color;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $sidebar-default-header-title-hover-color;
			}
		}
	}

	// Deprecated `.sidebar-header-actions > li a` in favor of
	// `.sidebar-actions > li a` as of v1.0.12 and will be removed in v2.0.0.

	a.sidenav-close,
	.sidebar-actions > li > a,
	.sidebar-actions > li > span > a,
	.sidebar-actions > li .dropdown-toggle,
	.sidebar-header-actions > li a {
		color: $sidebar-default-header-actions-link-color;
		text-decoration: none;

		&:focus,
		&:hover {
			color: $sidebar-default-header-actions-link-hover-color;
		}
	}

	.sidebar-panel {
		background-color: $sidebar-default-panel-bg;
	}
}

.sidebar-inverse {
	background-color: $sidebar-inverse-bg;
	color: $sidebar-inverse-color;

	.sidebar-header {
		.sidebar-title {
			color: $sidebar-inverse-header-title-color;
		}

		a.sidebar-title,
		.sidebar-title a {
			color: $sidebar-inverse-header-title-color;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $sidebar-inverse-header-title-hover-color;
			}
		}
	}

	// Deprecated `.sidebar-header-actions > li a` in favor of
	// `.sidebar-actions > li a` as of v1.0.12 and will be removed in v2.0.0.

	a.sidenav-close,
	.sidebar-actions > li > a,
	.sidebar-actions > li > span > a,
	.sidebar-actions > li .dropdown-toggle,
	.sidebar-header-actions > li a {
		color: $sidebar-inverse-header-actions-link-color;
		text-decoration: none;

		&:focus,
		&:hover {
			color: $sidebar-inverse-header-actions-link-hover-color;
		}
	}

	.sidebar-panel {
		background-color: $sidebar-inverse-panel-bg;
	}

	@if (variable-exists(atlas-theme)) {
		input {
			@include placeholder($brand-default);
		}

		.form-control {
			background-color: $sidebar-inverse-input-bg;
			border-width: 0;
			color: $brand-default;
			padding: 8px 12px;
		}
	}
}